import React, { useEffect, useState } from 'react';
import LinkComponent from '../../../components/common/Link';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import Stepper from '../../../components/common/stepper';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import workshopApi from '../workshop-api';
import TextEditionForm from './Steps/TextEdition';
import DateEditionWorkShop from './Steps/DateEdition';
import LinkEditionForm from './Steps/LinkEdition';
import InputEditionSummary from './Steps/EditionSummary';
import PillarAPI from '../../PillarContainer/pillar-api';

const WorkShopEdition = () => {
  const {
    state: { workshopId },
  } = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const [pillarList, setPillarList] = useState([]);
  const [workShopData, setWorkShopData] = useState({
    title: '',
    description: '',
    assistants: 0,
    startDate: '',
    image: '',
    recordingLink: '',
    workshopLink: '',
    status: 'inactive',
    hour: '',
    estimatedTime: '',
    receptor: '',
    id: 0,
    pillar: '',
    file: '',
  });

  useEffect(() => {
    PillarAPI.fetchActivePillars()
      .then((response) => {
        setPillarList(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los pilares:', error);
      });
  }, []);

  useEffect(() => {
    if (pillarList.length > 0) {
      (async () => {
        try {
          const { data } = await workshopApi.fetchWorkShop(workshopId);
          const pillar = pillarList.find((p) => p.id === data.pillar);
          const { startDate, ...workshop } = data;
          const date = startDate.substring(0, 10);
          const time = startDate.substring(11, 16);

          setWorkShopData({
            ...workshop,
            hour: time,
            startDate: date,
            pillar,
          });
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [pillarList]);

  const showAlert = (message) => {
    setAlertMessage(message);
  };

  const onNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const onPrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (values) => {
    setWorkShopData(workShopData);
  };

  const workShopSteps = [
    {
      title: 'Título y descripción',
      Component: TextEditionForm,
    },
    {
      title: 'Horario',
      Component: DateEditionWorkShop,
    },
    {
      title: 'Links',
      Component: LinkEditionForm,
    },
    {
      title: 'Resumen',
      Component: () => (
        <InputEditionSummary
          workshopData={workShopData}
          setWorkShopData={setWorkShopData}
          onPrev={onPrev}
          id={workshopId}
        />
      ),
    },
  ];

  return (
    <div className="client-container">
      <div className="client__back-container">
        <LinkComponent title="Volver" url="/talleres" icon="arrow_back" />
      </div>

      {loading ? null : (
        <Wizard title={'Editar taller'}>
          <div className="wizard__content">
            <div className="wizard__steps-container wizard-padding">
              {workShopSteps.map((currentStep, index) => (
                <Stepper
                  text={currentStep.title}
                  key={index}
                  isActive={index === activeStep}
                  isDone={index < activeStep}
                />
              ))}
            </div>

            {workShopSteps.map((currentStep, index) => {
              const C = currentStep.Component;
              return (
                index === activeStep && (
                  <C
                    pillars={pillarList}
                    onPrev={onPrev}
                    onNext={onNext}
                    workShopData={workShopData}
                    setWorkShopData={setWorkShopData}
                    key={`showing-step-${index}`}
                  />
                )
              );
            })}
          </div>
        </Wizard>
      )}
    </div>
  );
};

export default WorkShopEdition;
