import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/common/Button';
import LinkComponent from '../../../components/common/Link';
import WorkShopTable from './workShopTable';
import workshopApi from '../workshop-api';
import Alert from '../../../components/common/Alert';

const WorkShopHistory = () => {
  const [workshops, setWorkshops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageAlert, setMessageAlert] = useState();
  const history = useHistory();

  const fetchWorkShopData = async () => {
    try {
      const { data } = await workshopApi.fetchWorkshops();
      setWorkshops(data);
    } catch (error) {
      setMessageAlert({
        type: 'error',
        message: 'Hubo un error al obtener los talleres',
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setMessageAlert();
      }, 3000);
    }
  };

  useEffect(() => {
    fetchWorkShopData();
  }, [isLoading]);

  return (
    <div className="user-container">
      {messageAlert && (
        <Alert type={messageAlert.type}>{messageAlert.message}</Alert>
      )}
      <LinkComponent
        title="Volver"
        url="/formacion"
        icon="arrow_back"
      ></LinkComponent>

      <div className="content-panel">
        <div className="client__action-container">
          <span className="subtitle">Listado de talleres.</span>
          <Button
            type="button"
            primary
            onClick={() => history.push('/talleres/crear')}
          >
            <span className="material-icons">add</span>
            Nuevo taller
          </Button>
        </div>
        <WorkShopTable
          isLoading={isLoading}
          workshops={workshops}
          setIsLoading={setIsLoading}
          setMessageAlert={setMessageAlert}
          setWorkshops={setWorkshops}
        />
      </div>
    </div>
  );
};

export default WorkShopHistory;
