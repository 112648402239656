import React, { useMemo, useState } from 'react';
import EnhancedTable from '../../../components/common/Table';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import moment from 'moment';

import { Link } from 'react-router-dom';
import CopyElement from '../../../components/common/Copy';
import { ANNOUNCEMENT_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import StatusIndicator from '../../../components/common/StatusIndicator';
import workshopApi from '../workshop-api';
import { exportExcell } from '../../../utils/reports';
import { Modal } from '@material-ui/core';
import ModalContent from '../../../components/common/Modal/ModalContent';
import Button from '../../../components/common/Button';
import { useHistory } from 'react-router-dom';

import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

const WorkShopTable = ({
  workshops,
  setWorkshops,
  isLoading,
  setIsLoading,
  setMessageAlert,
}) => {
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workshopSelected, setWorkshopSelected] = useState({
    title: '',
    id: '',
  });
  const onClick = (id) => {
    setIsMenuOpen((prev) => {
      return { id: id, open: !prev.open };
    });
  };
  const formatDate = (date) => {
    return moment(date).format('DD-MM-yyyy');
  };

  const statusTextTranslate = (statusName) => {
    switch (statusName) {
      case 'active':
        return {
          text: 'Activo',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.ACTIVE,
        };

      case 'inactive':
        return {
          text: 'En espera',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.WAITING,
        };

      case 'finished':
        return {
          text: 'Finalizado',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.FINISH,
        };
      default:
        return {
          text: 'En espera',
          className: ANNOUNCEMENT_STATUS_CLASSNAMES.WAITING,
        };
    }
  };

  const onChangeStatus = async (id, status) => {
    try {
      const data = await workshopApi.updateStatusWorkshop(id, status);
      setMessageAlert({
        type: 'success',
        message: 'Taller actualizado exitosamente',
      });
    } catch (error) {
      setMessageAlert({
        type: 'error',
        message: 'Hubo un error al actualizar el estado',
      });
    } finally {
      //allows to  refresh parent container
      setIsLoading(true);
    }
  };

  const onDelete = async (id) => {
    try {
      const data = await workshopApi.deleteWorkshop(id);
      setIsModalOpen(false);
      setMessageAlert({
        type: 'success',
        message: 'Taller actualizado exitosamente',
      });
    } catch (error) {
      setMessageAlert({
        type: 'error',
        message: 'No se pudo eliminar el taller',
      });
    } finally {
      //allows to  refresh parent container
      setIsLoading(true);
    }
  };

  const fetchReport = async (id, name) => {
    try {
      const { data } = await workshopApi.fetchWorkshopReport(id);

      exportExcell(data.objects, name);
    } catch (error) {
      setMessageAlert({
        type: 'error',
        message: 'Hubo un error al obtener los participantes',
      });
    } finally {
    }
  };

  const changeToFeatured = async ({ id, isFeatured, dataWorkshops }) => {
    try {
      //Allows to change workshop featured faster before requesting
      const updatedWorkshops = dataWorkshops.map((obj) =>
        id === obj.id ? { ...obj, isFeatured: !isFeatured } : obj
      );
      setWorkshops(updatedWorkshops);

      const { data } = await workshopApi.updateWorkshop(id, {
        isFeatured: !isFeatured,
      });
    } catch (error) {
      //If doesn't work, returns to previous state
      const updatedWorkshops = dataWorkshops.map((obj) =>
        id === obj.id ? { ...obj, isFeatured } : obj
      );
      setWorkshops(updatedWorkshops);
      setMessageAlert({
        type: 'error',
        message: 'Hubo un error al destacar el taller seleccionado',
      });
    } finally {
    }
  };

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'title' },
      {
        Header: 'Fecha del taller',
        accessor: 'startDate',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Inscritos',
        accessor: 'assistants',
        Cell: ({ value, row: { original } }) => {
          return `${original.enrolledUsers}/${value}`;
        },
      },
      {
        Header: 'Sala',
        accessor: 'workshopLink',
        Cell: ({ value }) => {
          return (
            <div className="workshop__cell">
              <div className="text">
                <span className="link">{value}</span>
              </div>
              <a href={value} target="_blank" rel="noopener noreferrer">
                <CopyElement tooltipText="Sala del taller" copyText={value} />
              </a>
            </div>
          );
        },
      },

      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ value }) => (
          <StatusIndicator
            text={statusTextTranslate(value).text}
            statusClassName={statusTextTranslate(value).className}
          />
        ),
      },
      {
        Header: 'Destacado',
        accessor: 'isFeatured',
        Cell: ({ row }) => {
          const { id, isFeatured } = row?.values;
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                changeToFeatured({ id, isFeatured, dataWorkshops: workshops })
              }
            >
              {!isFeatured ? (
                <StarBorder
                  style={{
                    fill: 'rgba(219, 221, 224, 1)',
                  }}
                />
              ) : (
                <StarIcon
                  style={{
                    fill: 'rgba(255, 197, 61, 1)',
                  }}
                />
              )}
            </span>
          );
        },
      },
      {
        Header: 'Acción',
        accessor: 'id',
        Cell: ({ value, row: { original } }) => {
          return (
            <MenuContainer disable onClick={() => onClick(value)}>
              {isMenuOpen.id === value && (
                <Menu
                  open={isMenuOpen.open}
                  items={[
                    {
                      text: 'Editar',
                      action: () =>
                        history.push(`/talleres/editar/`, {
                          workshopId: value,
                        }),
                    },
                    {
                      status: original.status === 'finished' ? 'disabled' : '',
                      text:
                        original.status === 'active' ? 'Finalizar' : 'Activar',
                      action: () =>
                        original.status === 'active'
                          ? onChangeStatus(value, 'finished')
                          : onChangeStatus(value, 'active'),
                    },
                    {
                      status: !original.enrolledUsers && 'disabled',
                      text: 'Descargar participantes',
                      action: () => fetchReport(value, original.title),
                    },
                    {
                      status:
                        original.status !== 'inactive' ? 'disabled' : 'warning',
                      text: 'Eliminar',
                      action: () => {
                        setWorkshopSelected({
                          title: original.title,
                          id: original.id,
                        });
                        setIsModalOpen(true);
                      },
                    },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
      },
    ],
    [isMenuOpen, workshops, changeToFeatured]
  );

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalContent
          onClick={() => setIsModalOpen(false)}
          title="Eliminar taller"
        >
          <span className="title">
            ¿Estás seguro de eliminar el taller: {workshopSelected.title}
          </span>
          <div className="modal__action-container">
            <Button
              type="button"
              secondary
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
            <Button type="button" onClick={() => onDelete(workshopSelected.id)}>
              Si
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <EnhancedTable
        data={workshops}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Talleres"
      />
    </>
  );
};

export default WorkShopTable;
